import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { RadioButtonGroup, RadioButton } from '@class101/ui';
import { Playground, Props } from 'docz';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "radiobuttongroup"
    }}>{`RadioButtonGroup`}</h1>
    <p>{`RadioButtonGroup 입니다.`}</p>
    <h2 {...{
      "id": "props"
    }}>{`Props`}</h2>
    <Props of={RadioButtonGroup} mdxType="Props" />
    <h3 {...{
      "id": "vertical-stacking"
    }}>{`Vertical Stacking`}</h3>
    <Playground __position={1} __code={'() => {\n  const handleAlert = e => {\n    if (window) {\n      window.alert(e)\n    }\n  }\n  return (\n    <React.Fragment>\n      <RadioButtonGroup\n        value=\"test\"\n        stackingDirection=\"vertical\"\n        onChange={handleAlert}\n      >\n        <RadioButton value=\"test\">Option1</RadioButton>\n        <RadioButton value=\"test2\">Option2</RadioButton>\n      </RadioButtonGroup>\n      <RadioButtonGroup\n        stackingDirection=\"vertical\"\n        textAlign=\"center\"\n        onChange={handleAlert}\n      >\n        <RadioButton value=\"test\">Option1</RadioButton>\n        <RadioButton value=\"test2\">Option2</RadioButton>\n      </RadioButtonGroup>\n      <RadioButtonGroup\n        stackingDirection=\"vertical\"\n        showBorder={false}\n        showDivider\n        onChange={handleAlert}\n      >\n        <RadioButton value=\"test\">Option1</RadioButton>\n        <RadioButton value=\"test2\">Option2</RadioButton>\n      </RadioButtonGroup>\n    </React.Fragment>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      RadioButtonGroup,
      RadioButton,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const handleAlert = e => {
          if (window) {
            window.alert(e);
          }
        };

        return <React.Fragment>
        <RadioButtonGroup value="test" stackingDirection="vertical" onChange={handleAlert} mdxType="RadioButtonGroup">
          <RadioButton value="test" mdxType="RadioButton">Option1</RadioButton>
          <RadioButton value="test2" mdxType="RadioButton">Option2</RadioButton>
        </RadioButtonGroup>
        <RadioButtonGroup stackingDirection="vertical" textAlign="center" onChange={handleAlert} mdxType="RadioButtonGroup">
          <RadioButton value="test" mdxType="RadioButton">Option1</RadioButton>
          <RadioButton value="test2" mdxType="RadioButton">Option2</RadioButton>
        </RadioButtonGroup>
        <RadioButtonGroup stackingDirection="vertical" showBorder={false} showDivider onChange={handleAlert} mdxType="RadioButtonGroup">
          <RadioButton value="test" mdxType="RadioButton">Option1</RadioButton>
          <RadioButton value="test2" mdxType="RadioButton">Option2</RadioButton>
        </RadioButtonGroup>
      </React.Fragment>;
      }}
    </Playground>
    <h3 {...{
      "id": "horizontal-stacking"
    }}>{`Horizontal Stacking`}</h3>
    <Playground __position={2} __code={'() => {\n  const handleAlert = e => {\n    if (window) {\n      window.alert(e)\n    }\n  }\n  return (\n    <React.Fragment>\n      <RadioButtonGroup stackingDirection=\"horizontal\" onChange={handleAlert}>\n        <RadioButton value=\"test\">Option1</RadioButton>\n        <RadioButton value=\"test2\">Option2</RadioButton>\n      </RadioButtonGroup>\n      <RadioButtonGroup\n        stackingDirection=\"horizontal\"\n        showBorder={false}\n        showDivider\n        onChange={handleAlert}\n      >\n        <RadioButton value=\"test\">Option1</RadioButton>\n        <RadioButton value=\"test2\">Option2</RadioButton>\n      </RadioButtonGroup>\n      <RadioButtonGroup\n        stackingDirection=\"horizontal\"\n        showBorder={false}\n        showDivider\n        textAlign=\"center\"\n        onChange={handleAlert}\n      >\n        <RadioButton value=\"test\">Option1</RadioButton>\n        <RadioButton value=\"test2\">Option2</RadioButton>\n      </RadioButtonGroup>\n    </React.Fragment>\n  )\n}'} __scope={{
      props,
      DefaultLayout,
      RadioButtonGroup,
      RadioButton,
      Playground,
      Props,
      DefaultLayout,
      _frontmatter
    }} mdxType="Playground">
  {() => {
        const handleAlert = e => {
          if (window) {
            window.alert(e);
          }
        };

        return <React.Fragment>
        <RadioButtonGroup stackingDirection="horizontal" onChange={handleAlert} mdxType="RadioButtonGroup">
          <RadioButton value="test" mdxType="RadioButton">Option1</RadioButton>
          <RadioButton value="test2" mdxType="RadioButton">Option2</RadioButton>
        </RadioButtonGroup>
        <RadioButtonGroup stackingDirection="horizontal" showBorder={false} showDivider onChange={handleAlert} mdxType="RadioButtonGroup">
          <RadioButton value="test" mdxType="RadioButton">Option1</RadioButton>
          <RadioButton value="test2" mdxType="RadioButton">Option2</RadioButton>
        </RadioButtonGroup>
        <RadioButtonGroup stackingDirection="horizontal" showBorder={false} showDivider textAlign="center" onChange={handleAlert} mdxType="RadioButtonGroup">
          <RadioButton value="test" mdxType="RadioButton">Option1</RadioButton>
          <RadioButton value="test2" mdxType="RadioButton">Option2</RadioButton>
        </RadioButtonGroup>
      </React.Fragment>;
      }}
    </Playground>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      